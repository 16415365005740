import React, { useContext, useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Box,
  CircularProgress,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { AuthContext } from "../auth/ContextAuthentication";
import useAxiosConfig from "../config/AxiosConfig";
import ReceiptTable from "../component/ReceiptTable";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0C7240",
    },
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#0C7240",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#0C7240",
            fontWeight: "700",
          },
        },
      },
    },
  },
});

const Receipts = () => {
  const axiosInstance = useAxiosConfig();
  const { setReceiptData } = useContext(AuthContext);
  const { setReceiptPagination } = useContext(AuthContext);

  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const headerData = ["FullName", "Amount", "Status", "Phone Number"];

  const fetchReceipts = async (type) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        `api/admin/payments/view-all-recipts?type=${type}`
      );

      setReceiptData(response.data.receipts);
      const { currentPage, totalPages, totalCounts } = response.data;

      setReceiptPagination({
        currentPage: currentPage,
        totalPages: totalPages,
        totalCounts: totalCounts,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReceipts("manual");
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    const type = newValue === 0 ? "manual" : "online";
    fetchReceipts(type);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "98%",
            margin: "0 auto",
          }}
        >
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="receipt types"
            // centered
          >
            <Tab label="Manual" />
            <Tab label="Online" />
          </Tabs>
        </Box>

        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <ReceiptTable
            headerData={headerData}
            type={activeTab === 0 ? "manual" : "online"}
          />
        )}
      </Box>
    </ThemeProvider>
  );
};

export default Receipts;
