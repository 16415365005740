import React, { useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider, AuthContext } from "./auth/ContextAuthentication";
import { ErrorLoadingProvider } from "./auth/ErrorLoadingContext";
import Login from "./pages/Login";
import UpdatePassword from "./pages/UpdatePassword";
import GroundProfile from "./pages/GroundProfile";
import PlayersProfile from "./pages/PlayersProfile";
import MatchProfile from "./pages/MatchProfile";
import Dashboard from "./pages/Dashboard";
import SideNav from "./component/SideNav";
import TopNav from "./component/TopNav";
import PaymentVerfication from "./pages/PaymentVerfication";
import Receipts from "./pages/Receipts";
import CreatePromotion from "./pages/CreatePromotion";
import ViewAllPromotions from "./pages/ViewAllPromotions";
import Bookings from "./pages/Bookings";
import SportsProfile from "./pages/SportsProfile";

const App = () => {
  return (
    <AuthProvider>
      <ErrorLoadingProvider>
        <Router>
          <AppContent />
        </Router>
      </ErrorLoadingProvider>
    </AuthProvider>
  );
};

const AppContent = () => {
  const { authenticated, open } = useContext(AuthContext);

  return (
    <div className="flex min-h-screen bg-gray-100">
      {authenticated && <SideNav />}
      <div className="flex-1">
        {authenticated && <TopNav />}
        <main
          className={`flex-1 transition-all duration-300 ${
            authenticated ? (open ? "ml-20 p-4" : "ml-60 p-4") : ""
          }`}
          style={
            authenticated
              ? {
                  width: open ? "calc(100vw - 5rem)" : "calc(100vw - 15rem)",
                }
              : {}
          }
        >
          <Routes>
            <Route
              path="/"
              element={authenticated ? <Dashboard /> : <Login />}
            />
            <Route path="/login" element={<Login />} />
            <Route
              path="/playersprofile"
              element={authenticated ? <PlayersProfile /> : <Login />}
            />
            <Route
              path="/groundprofile"
              element={authenticated ? <GroundProfile /> : <Login />}
            />
            <Route
              path="/bookings"
              element={authenticated ? <Bookings /> : <Login />}
            />
            <Route
              path="/updatepassword"
              element={authenticated ? <UpdatePassword /> : <Login />}
            />
            <Route
              path="/matchprofile"
              element={authenticated ? <MatchProfile /> : <Login />}
            />
            <Route
              path="/receipts"
              element={authenticated ? <Receipts /> : <Login />}
            />
            <Route
              path="/sportsprofile"
              element={authenticated ? <SportsProfile /> : <Login />}
            />
            <Route
              path="/paymentverification"
              element={authenticated ? <PaymentVerfication /> : <Login />}
            />
            <Route
              path="/promotions/create"
              element={authenticated ? <CreatePromotion /> : <Login />}
            />
            <Route
              path="/promotions/view-all"
              element={authenticated ? <ViewAllPromotions /> : <Login />}
            />
          </Routes>
        </main>
      </div>
    </div>
  );
};

export default App;
