import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../auth/ContextAuthentication";
import { ErrorLoadingContext } from "../auth/ErrorLoadingContext";
import useAxiosConfig from "../config/AxiosConfig";
import BookingTable from "../component/BookingTable";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";

const SportsProfile = () => {
  const axiosInstance = useAxiosConfig();
  const { setBookingData } = useContext(AuthContext);
  const { setLoading, setError } = useContext(ErrorLoadingContext);
  const [sports, setSports] = useState([]);
  const [grounds, setGrounds] = useState([]);
  const [selectedSport, setSelectedSport] = useState(null);
  const [selectedGround, setSelectedGround] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [groundMap, setGroundMap] = useState([]);
  const [isLoadingGrounds, setIsLoadingGrounds] = useState(false);
  const [isFetchingBookings, setIsFetchingBookings] = useState(false);

  const headerData = [
    "User's Name",
    "Phone No",
    "Booking date",
    "Ground Name",
    "Field",
    "Time Slot",
    "Advance Amount",
    "Remaining Amount",
    "Promo Code",
    "Manager's Name",
    "Manager's No",
    "Status",
    "Actions",
  ];

  useEffect(() => {
    const fetchAllSports = async () => {
      setIsLoadingGrounds(true);
      try {
        const response = await axiosInstance.get("/api/ground/get-all-sports");

        const sports = response.data.data;

        setSports(sports);
      } catch (error) {
        setError("Error fetching sports");
      } finally {
        setIsLoadingGrounds(false);
      }
    };

    fetchAllSports();
  }, []);

  const fetchGroundsBySport = async (sportId) => {
    setIsLoadingGrounds(true);
    try {
      const response = await axiosInstance.get(
        `api/admin/sports/get-grounds-by-sports/${sportId}`
      );
      const grounds = response.data.data;
      setGrounds(grounds);
    } catch (error) {
      setError("Error fetching grounds");
      setGrounds([]);
    } finally {
      setIsLoadingGrounds(false);
    }
  };

  useEffect(() => {
    const fetchBookings = async () => {
      if (!selectedSport || !selectedGround) {
        return;
      }

      setIsFetchingBookings(true);
      try {
        const response = await axiosInstance.get(
          `api/admin/sports/get-bookings-by-grounds-and-sports/${selectedGround._id}/${selectedSport._id}`
        );

        setBookingData(response.data.data || []);
      } catch (error) {
        setError("Error fetching bookings");
        setBookingData([]);
      } finally {
        setIsFetchingBookings(false);
      }
    };

    fetchBookings();
  }, [selectedSport, selectedGround]);

  return (
    <div className="p-4 w-full">
      <div className="mb-4 flex gap-4">
        <Autocomplete
          options={sports}
          getOptionLabel={(option) => option.name}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Sport"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoadingGrounds ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          onChange={(event, newValue) => {
            setSelectedSport(newValue);
            if (newValue) {
              fetchGroundsBySport(newValue._id);
            } else {
              setGrounds([]);
            }
          }}
          loading={isLoadingGrounds}
        />
        <Autocomplete
          options={grounds}
          getOptionLabel={(option) => option.name}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Ground"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoadingGrounds ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          onChange={(event, newValue) => {
            setSelectedGround(newValue);
          }}
          loading={isLoadingGrounds}
        />
      </div>
      <BookingTable
        headerData={headerData}
        isLoading={isFetchingBookings}
        groundMap={groundMap}
      />
    </div>
  );
};

export default SportsProfile;
