import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Autocomplete,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import axios from "axios";

const AddReceipt = ({ open, onClose, axiosInstance, setNotification }) => {
  const [newReceipt, setNewReceipt] = useState({
    userId: "",
    amount: "",
    transactionImage: "",
    fullName: "",
    email: "",
  });
  const [isCreating, setIsCreating] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const abortControllerRef = useRef(null); // Reference for the AbortController

  useEffect(() => {
    if (open) {
      fetchAllUsers();
    }
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort(); // Abort the fetch request if modal is closed or unmounted
      }
    };
  }, [open]);

  const fetchAllUsers = async () => {
    setLoadingUsers(true);
    abortControllerRef.current = new AbortController(); // Initialize the AbortController

    try {
      const response = await axiosInstance.get("api/admin/get-all-users", {
        signal: abortControllerRef.current.signal, // Attach the abort signal to the request
      });

      setAllUsers(response.data.users);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Fetch request canceled");
      } else {
        setNotification({
          open: true,
          message: "Error fetching users.",
          severity: "error",
        });
      }
    }
    setLoadingUsers(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewReceipt((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleUserSelect = (event, value) => {
    if (value) {
      setNewReceipt((prevState) => ({
        ...prevState,
        userId: value._id,
        fullName: value.fullName || "",
        email: value.email || "",
      }));
    } else {
      setNewReceipt((prevState) => ({
        ...prevState,
        userId: "",
        fullName: "",
        email: "",
      }));
    }
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("img", file);

    try {
      const response = await axiosInstance.post("api/upload/image", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });

      return response.data.img_url;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  const handleCreateReceipt = async () => {
    setIsCreating(true);
    setUploadProgress(0);

    if (
      !newReceipt.userId ||
      !newReceipt.amount ||
      !newReceipt.transactionImage
    ) {
      setNotification({
        open: true,
        message: "Please fill all required fields.",
        severity: "error",
      });
      setIsCreating(false);
      return;
    }

    try {
      let imageUrl = "";
      if (newReceipt.transactionImage) {
        imageUrl = await uploadImage(newReceipt.transactionImage);
      }

      const receiptData = {
        userId: newReceipt.userId,
        amount: Number(newReceipt.amount),
        transactionImage: imageUrl,
      };

      await axiosInstance.post("api/admin/payments/send-credits", receiptData);

      setNotification({
        open: true,
        message: "Receipt created successfully.",
        severity: "success",
      });
      resetForm();
      onClose();
    } catch (error) {
      setNotification({
        open: true,
        message: "Error creating receipt.",
        severity: "error",
      });
      setIsCreating(false);
    }
  };

  const resetForm = () => {
    setNewReceipt({
      userId: "",
      amount: "",
      transactionImage: "",
      fullName: "",
      email: "",
    });
    setUploadProgress(0);
    setIsCreating(false);
  };

  const handleClose = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort any ongoing API request
    }
    resetForm();
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Create New Receipt</DialogTitle>
      <DialogContent>
        <Autocomplete
          options={allUsers}
          getOptionLabel={(option) => option.phone_no || ""}
          onChange={handleUserSelect}
          loading={loadingUsers}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Phone Number"
              margin="dense"
              fullWidth
              required
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingUsers ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        <TextField
          label="Full Name"
          name="fullName"
          value={newReceipt.fullName}
          onChange={handleInputChange}
          margin="dense"
          fullWidth
        />
        <TextField
          label="Email"
          name="email"
          value={newReceipt.email}
          onChange={handleInputChange}
          margin="dense"
          fullWidth
        />
        <TextField
          label="Amount"
          name="amount"
          type="number"
          value={newReceipt.amount}
          onChange={handleInputChange}
          margin="dense"
          fullWidth
          required
        />
        <TextField
          type="file"
          accept="image/*"
          onChange={(e) => {
            setNewReceipt((prevState) => ({
              ...prevState,
              transactionImage: e.target.files[0],
            }));
          }}
          fullWidth
          margin="dense"
          required
        />
        {uploadProgress > 0 && uploadProgress < 100 && (
          <LinearProgress variant="determinate" value={uploadProgress} />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleCreateReceipt}
          color="primary"
          disabled={isCreating}
        >
          {isCreating ? <CircularProgress size={24} /> : "Create Receipt"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddReceipt;
