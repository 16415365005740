import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../auth/ContextAuthentication";
import { ErrorLoadingContext } from "../auth/ErrorLoadingContext";
import useAxiosConfig from "../config/AxiosConfig";
import BookingTable from "../component/BookingTable";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";

const Bookings = () => {
  const axiosInstance = useAxiosConfig();
  const { setBookingData } = useContext(AuthContext);
  const { setLoading, setError } = useContext(ErrorLoadingContext);
  const [grounds, setGrounds] = useState([]);
  const [selectedGround, setSelectedGround] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [groundMap, setGroundMap] = useState([]);
  const [isLoadingGrounds, setIsLoadingGrounds] = useState(false);
  const [isFetchingBookings, setIsFetchingBookings] = useState(false);

  const headerData = [
    "User's Name",
    "Phone No",
    "Booking date",
    "Ground Name",
    "Field",
    "Time Slot",
    "Advance Amount",
    "Remaining Amount",
    "Promo Code",
    "Manager's Name",
    "Manager's No",
    "Status",
    "Actions",
  ];

  //   fetching grounds for autocomplete and to apply filter in bookings
  useEffect(() => {
    const fetchAllGrounds = async () => {
      setIsLoadingGrounds(true);
      try {
        const response = await axiosInstance.get("api/admin/get-all-grounds");
        const allGrounds = response.data.grounds;

        // Populate groundMap with ground and field names
        const groundMap = allGrounds.reduce((map, ground) => {
          map[ground._id] = {
            name: ground.name,
            fields: ground.fields.reduce((acc, field) => {
              acc[field._id] = field.name;
              return acc;
            }, {}),
          };
          return map;
        }, {});

        setGrounds(allGrounds);
        setGroundMap(groundMap);
      } catch (error) {
        setError("Error fetching grounds");
      } finally {
        setIsLoadingGrounds(false);
      }
    };

    fetchAllGrounds();
  }, []);

  useEffect(() => {
    const fetchBookings = async () => {
      setIsFetchingBookings(true);
      try {
        let response;

        if (selectedGround && selectedDate) {
          // Fetch bookings for a specific ground and date
          response = await axiosInstance.get(
            `api/admin/get-ground-booking-admin?groundId=${selectedGround._id}&date=${selectedDate}`
          );
        } else if (selectedGround) {
          // Fetch bookings for a specific ground
          response = await axiosInstance.get(
            `api/admin/get-ground-booking-admin?groundId=${selectedGround._id}`
          );
        } else {
          // Default: Fetch monthly bookings
          response = await axiosInstance.get("api/booking/new-month-booking");
        }

        setBookingData(
          response.data.bookings || response.data.populatedBookings || []
        );
      } catch (error) {
        setError("Error fetching bookings");
        setBookingData([]);
      } finally {
        setIsFetchingBookings(false);
      }
    };

    fetchBookings();
  }, [selectedGround, selectedDate]);

  return (
    <div className="p-4 w-full">
      <div className="mb-4 flex gap-4">
        <Autocomplete
          options={grounds}
          getOptionLabel={(option) => option.name}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Ground"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoadingGrounds ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          onChange={(event, newValue) => {
            setSelectedGround(newValue);
          }}
          loading={isLoadingGrounds}
        />
        <TextField
          label="Select Date"
          type="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          style={{ width: 300 }}
        />
      </div>
      <BookingTable
        headerData={headerData}
        isLoading={isFetchingBookings}
        groundMap={groundMap}
      />
    </div>
  );
};

export default Bookings;
